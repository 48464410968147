import Vue from 'vue'
import Vuex from 'vuex'
import user from './user'
import appData from './appData'
import settings from './settings'
import library from './library'

Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		user,
		settings,
		appData,
		library,
	},
	state: {
		loading: false,
		instances: [],
		instance: null,
		hives: [],
		cats: [],
		uploadDialog: false,
		folders: [],
		files: {},
		currentFolder: '/',
		theApp: {},
		roles: [],
		pageButtons: [],
		roleModal: {
			show: false,
			type: '',
			role: {},
		},
		addUser: {
			show: false,
		},
		roleCounts: {},
		editRole: {
			role: '',
			update: 0
		},
		deleteRole: {
			role: '',
			update: 0
		},
		fileStructure: [],
		selectedRole: null
	},
	mutations: {
		LOAD(state, data = false) {
			state.loading = data;
		},
		UPDATE_SELECTED_ROLE(state, role) {
			state.selectedRole = role
		},
		SET_FILE_STRUCTURE(state, data) {
			state.fileStructure = data;
		},
		UPDATE_EDIT_ROLE_NUM(state, role) {
			state.editRole = {
				role: role,
				update: Date.now()
			}
		},
		UPDATE_DELETE_ROLE_NUM(state, role) {
			state.deleteRole = {
				role: role,
				update: Date.now()
			}
		},
		UPDATE_ROLE_COUNTS(state, data) {
			state.roleCounts = data;
		},
		ADD_ROLE: (state, role) => {
			state.roles.push(role)
		},
		UPDATE_ROLE: (state, role) => {
			state.roles = state.roles.map(r => {
				if (r.id === role.id) {
					return role
				}
				return r
			})
		},
		SET_ROLES: (state, roles) => {
			state.roles = roles
		},
		PAGE_BUTTONS(state, buttons) {
			state.pageButtons = buttons
		},
		EDIT_ROLE(state, data) {
			state.roleModal.show = true
			state.roleModal.type = 'edit'
			state.roleModal.role = JSON.parse(JSON.stringify(data))
		},
		ADD_USER(state) {
			state.addUser.show = true
		},
		ADD_ROLE_MODAL(state) {
			state.roleModal.show = true
			state.roleModal.type = 'add'
		},
		CLOSE_ROLE_MODAL(state) {
			state.roleModal.show = false
		},
		CLOSE_ADD_USER(state) {
			state.addUser.show = false
		},
		SET_APP(state, data) {
			state.theApp = data
		},
		cancelUpload: (state) => {
			state.uploadDialog = false
		},
		uploadDialog: (state, folders = []) => {
			state.uploadDialog = true
			state.folders = folders
		},
		SET_INSTANCES: (state, data) => {
			data = data.map(x => {
				let own = false
				if (x.owner === state.user.user.id) own = true
				return {
					...x,
					own
				}
			})
			state.instances = data
		},
		SET_INSTANCE: (state, data) => {
			state.instance = data
			state.cats = data.activeApps
		},
		SET_USERS(state, data) {
			state.instance.users = data
		}

	},
	getters: {

	},
	actions: {

	},
})
